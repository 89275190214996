// Import our CSS
import styles from '../../css/intelligence/app.scss';
import 'chartjs-plugin-colorschemes';
import Vue from 'vue'
import Datepicker from "vuejs-datepicker";

window.Vue = require('vue');

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    const vmh = new Vue({
        el: '#app-header',
        components: {
            'header-form-company-search'   : () => import('../../../cms/modules/intelligencemodule/src/vue/HeaderFormCompanySearch.vue'),
            'header-form-search-deals'     : () => import('../../../cms/modules/intelligencemodule/src/vue/HeaderFormSearchDeals.vue'),
            'header-form-search-investors' : () => import('../../../cms/modules/intelligencemodule/src/vue/HeaderFormSearchInvestors.vue'),
        },
        data() {
            return {
                isSearchVisible: false,
                isScrolled: false,
                activeTopSearchTab: 0,
                dealtypes: [],
                locations: [],
                sectors: [],
                investortypes: []
            };
        },
        computed: {
            searchFormTop() {
                // Check if the body has the "scrolled" class
                return this.isScrolled ? '79px' : '108px';
            }
        },
        methods: {
            toggleSearch() {
                this.isSearchVisible = !this.isSearchVisible;
            },
            handleScroll() {
                // Update isScrolled based on the scroll position
                this.isScrolled = window.pageYOffset >= 50;
            },
            setActiveTopSearchTab(index) {
                this.activeTopSearchTab = index;
            },
            async fetchInvestorData() {
                try {
                    const response = await fetch(window.IntelligenceURL + '/json/investor-data');
                    const data = await response.json();
                    this.dealtypes = data.dealtypes || [];
                    this.locations = data.locations || [];
                    this.sectors = data.sectors || [];
                    this.investortypes = data.investortypes || [];
                } catch (error) {
                    console.error("Error fetching investor data:", error);
                }
            },
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
            this.fetchInvestorData();
        },

        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    });

    // Create our vue instance
    const vm = new Vue({
        el: "#app",

        components: {
            Datepicker,
            'mobile-menu'           : () => import(/* webpackChunkName: "mobilemenu" */ '../../vue/intelligence/MobileMenu.vue'),
            'form-fundraising'      : () => import(/* webpackChunkName: "form-fundraising" */ '../../../cms/modules/intelligencemodule/src/vue/FormFundraising.vue'),
            'form-fund-commitment'  : () => import(/* webpackChunkName: "form-fund-commitment" */ '../../../cms/modules/intelligencemodule/src/vue/FormFundCommitment.vue'),
            'form-investment'       : () => import(/* webpackChunkName: "form-investment" */ '../../../cms/modules/intelligencemodule/src/vue/FormInvestment.vue'),
            'form-exit'             : () => import(/* webpackChunkName: "form-exit" */ '../../../cms/modules/intelligencemodule/src/vue/FormExit.vue'),
            'form-search'           : () => import(/* webpackChunkName: "form-search" */ '../../../cms/modules/intelligencemodule/src/vue/FormSearch.vue'),
            'form-search-investors' : () => import(/* webpackChunkName: "form-search-investors" */ '../../../cms/modules/intelligencemodule/src/vue/FormSearchInvestors.vue'),
            'impersonate-member'    : () => import(/* webpackChunkName: "impersonate-member" */ '../../../cms/modules/intelligencemodule/src/vue/ImpersonateMember.vue'),
            'form-company-search'   : () => import(/* webpackChunkName: "form-companysearch" */ '../../../cms/modules/intelligencemodule/src/vue/FormCompanySearch.vue'),
            'chart-doughnut'        : () => import(/* webpackChunkName: "chart-doughnut" */ '../../../cms/modules/intelligencemodule/src/vue/ChartDoughnut.vue'),
            'chart-map'             : () => import(/* webpackChunkName: "chart-map" */ '../../../cms/modules/intelligencemodule/src/vue/ChartMap.vue'),
            'chart-quarter-over-quarter'    : () => import(/* webpackChunkName: "chart-quarter-over-quarter" */ '../../../cms/modules/intelligencemodule/src/vue/ChartQuarterOverQuarter.vue'),
            'chart-total-investments'       : () => import(/* webpackChunkName: "chart-total-investments" */ '../../../cms/modules/intelligencemodule/src/vue/ChartTotalInvestments.vue'),
            'chart-investments-by-year'     : () => import(/* webpackChunkName: "chart-investments-by-year" */ '../../../cms/modules/intelligencemodule/src/vue/ChartInvestmentsByYear.vue'),
        },

        data: {
            showRetrievalModal: false,
            selectedRegion: '',
            activeTab: 0,
            dateFrom: document.getElementById('dateFromHidden') ? document.getElementById('dateFromHidden').value + " 00:00:00" : null,
            dateTo: document.getElementById('dateToHidden') ? document.getElementById('dateToHidden').value + " 00:00:00" : null
        },

        methods: {
            // handleScroll (event) {
            handleScroll () {
                if( window.pageYOffset >= 50 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }
            },

            showMore( parentElement ) {
                let trList  = document.querySelectorAll('table#' + parentElement + 'Table tr.is-hidden')
                let trArray = [...trList];
                trArray.forEach(tr => {
                    tr.classList.remove('is-hidden')
                });

                document.querySelector('a#' + parentElement + 'More').classList.add('is-hidden')
            },

            openModal( modalRef ) {
                this.$refs[modalRef].classList.add( 'is-active' );
                this.$refs[modalRef].focus();
            },

            closeModal( modalRef ) {
                this.$refs[modalRef].classList.remove( 'is-active' );
            },

            getCurrentRegion() {
                const urlParams = new URLSearchParams(window.location.search);
                return urlParams.get('region') || '';
            },

            updateRegion() {
                const urlParams = new URLSearchParams(window.location.search);

                if (this.selectedRegion) {
                    urlParams.set('region', this.selectedRegion);
                } else {
                    urlParams.delete('region');
                }

                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.location.href = newUrl;
            },

            setActiveTab(index) {
                this.activeTab = index;
            },

            formatDate(date) {
                if (!date) return '';
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const day = String(d.getDate()).padStart(2, '0');
                return `${year}-${month}-${day}`;
            },

            applyDateFilter() {
                const fromDate = this.formatDate(this.dateFrom);
                const toDate = this.formatDate(this.dateTo);

                if (!fromDate || !toDate) {
                    alert('Please select both "From" and "To" dates.');
                    return;
                }

                const urlParams = new URLSearchParams(window.location.search);

                if (fromDate) {
                    urlParams.set('from', fromDate);
                }
                if (toDate) {
                    urlParams.set('to', toDate);
                }

                const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                window.location.href = newUrl;
            },
        },

        created () {
            document.body.classList.add("js-loaded");
            window.addEventListener('scroll', this.handleScroll);
            setTimeout(() => document.body.classList.add( window.pageYOffset >= 10 ? "scrolled" : "notempty" ), 1000);
            this.selectedRegion = this.getCurrentRegion();
        },

        //destroyed () {
        unmounted () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    });

    return { vm, vmh };
};

// Execute async function
main().then( ({ vm, vmh }) => {
    window.vmh  = vmh
    window.vm  = vm
    window.Vue = Vue;
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
